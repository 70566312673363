export const addNewestJson = (json) => {
	return {
		type: "ADD_NEWEST_JSON",
		payload: json
	}
}

export const addHottestDealsJson = (json) => {
	return {
		type: "ADD_HOTTEST_DEALS_JSON",
		payload: json
	}
}

export const addStreetwearJson = (json) => {
	return {
		type: "ADD_STREETWEAR_JSON",
		payload: json
	}
}

export const addSneakerJson = (json) => {
	return {
		type: "ADD_SNEAKER_JSON",
		payload: json
	}
}

export const addAccessoiresJson = (json) => {
	return {
		type: "ADD_ACCESSOIRES_JSON",
		payload: json
	}
}

export const addTextJson = (json) => {
	return {
		type: "ADD_TEXT_JSON",
		payload: json
	}
}

export const change = (position) => {
	return {
		type: "ADD_SCROLL_POSITION",
		payload: position
	}
}

export const changeSiteNumber = (number) => {
	return {
		type: "ADD_SITE_NUMBER",
		payload: number
	}
}

export const addLinkJson = (number) => {
	return {
		type: "ADD_LINK_JSON",
		payload: number
	}
}