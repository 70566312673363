import React , {useEffect, useState} from 'react'
import "./itemView.css"
import Header from "../HELPER/header"
import Footer from "../HELPER/Footer"
import firebase from "firebase/app"
import linkIcon from "../ASSETS/LINK_ICON.svg"
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import style from './custom-scrollbar.css';
import { useSelector, useDispatch } from "react-redux"
import {addTextJson, addLinkJson} from "../REDUX/actions"
import { createVigLink } from "../HELPER/createLink"


function ItemView(props) {

	// JSON
	const [clothingJson, setClothingJson] = useState(props.location.state ? props.location.state.clothing : {})
	const savedText = useSelector(state => state.textApi)
	const savedLinks = useSelector(state => state.linkApi)

	// Redux
	const dispatch = useDispatch()

	const executeScroll = position => {window.scrollTo(0, position)}

	useEffect(() => {
		const cat = props.match.params.category 
		if (cat !== "hottest_deals" && cat !== "streetwear" && cat !== "sneaker" && cat !== "accessoires" && cat !== "home") {
			props.history.push("/404")
		}

		document.title = `Deep Sale - Designer Clothing`

		if (props.location.state === undefined) { fetchClothing() } 
		else { document.title = `Deep Sale - ${clothingJson.brand} ${clothingJson.title} Sale` }

		if (savedText === 0) { fetchText() }
		if (savedLinks === 0) { fetchLinks() }

		executeScroll(0)

		// eslint-disable-next-line 
	}, [])


	// FETCH JSON
	const fetchClothing = async () => {
		try { 
			const data = await fetch(`https://fashion-api-v1.herokuapp.com/api/BS/outfits/uniqueID=${props.match.params.id}`) 
			let items = await data.json()	
			setClothingJson(items[0])

			document.title = `Deep Sale - ${items[0].brand} ${items[0].title} Sale`

		} catch (err) {
			console.log("Fetch JSON error: ", err)
			props.history.push("/")
		}
	}

	// FETCH Text JSON
	const fetchText = async () => {
		try { 
			const data = await fetch(`https://fashion-api-v1.herokuapp.com/api/BS/text`)
			const items = await data.json()		

			// Redux
			dispatch(addTextJson(items))
		} catch (err) {
			console.log("Fetch JSON from Text API error: ", err)
		}
	}

	// FETCH Links
	const fetchLinks = async () => {
		try { 
			const data = await fetch(`https://fashion-api-v1.herokuapp.com/api/BS/links`)
			const items = await data.json()		

			// Redux
			dispatch(addLinkJson(items))
		} catch (err) {
			console.log("Fetch JSON from Text API error: ", err)
		}
	}


	// ON CLICK
	const onBuyClick = () => {
		// const userAcceptStats = getCookie("userAcceptStats")
		// if (userAcceptStats) {
		// 	if (userAcceptStats === "true") { 
				const analytics = firebase.analytics();
				analytics.logEvent("click_on_buy", {product: clothingJson.uniqueID})
		// 	}
		// } 

		let link = ""
		console.log(clothingJson.buyLinksUnpaid)
		if (clothingJson.buyLinksUnpaid === undefined || clothingJson.buyLinksUnpaid === null || clothingJson.buyLinksUnpaid.length === 0) {
			link = clothingJson.buyLinks[0]
		} else {
			link = createVigLink(clothingJson.buyLinks[0], "clothing")
		}

		window.open(link, '_blank')
	}


	const pickShopLink = (cat) => {
		const shop = clothingJson.shops ? clothingJson.shops[0].toLowerCase() : ""
		if (savedLinks === 0) {return ""}

		if (cat === "shipping") {
			for (const links of savedLinks) {
				if (!links.linkID) { return "0" }
				if (links.linkID.toLowerCase() === shop) { return links.shipping }
			}

		} else if (cat === "returns") { 
			for (const links of savedLinks) {
				if (!links.linkID) { return "0" }
				if (links.linkID.toLowerCase() === shop) { return links.returns }
			}

		} else if (cat === "taxes") { 
			for (const links of savedLinks) {
				if (!links.linkID) { return "0" }
				if (links.linkID.toLowerCase() === shop) { return links.taxes }
			}
		}
	}


	// UI
	const images = () => {
		if (!clothingJson.images3x) {return}

		return clothingJson.images3x.map(image => 
			<img className="image" src={image} alt={`${clothingJson.brand} ${clothingJson.title} Sale`} key={image}></img>
		)
	}

	const text = () => {
		if (savedText === 0) {return ""}
		if (!clothingJson.textID) { return "" }

		for (const element of savedText) {
			if (element.textID.toLowerCase() === clothingJson.textID.toLowerCase()) {
				return ( 
					<p>{element.text1} <br/> <br/> {element.text2}</p> 
				)
			}
		}
	}


	if (clothingJson === undefined) {return ""}

	return(
		<div className="ItemView">
			<Header cat={"home"}/>

				<div className="image-container">
					<div className="wrapper">
						{images()}
					</div>
				</div>

				<div className="clothing-info-container">
					<div className="infos">
						<h2>{clothingJson.brand}</h2>
						<h3>{clothingJson.title}</h3>
						<div className="price-container">
							<p className="salePrice">{clothingJson.salePrice} {clothingJson.currency ? clothingJson.currency : "EUR"}</p>
							<p className="originalPrice">{clothingJson.originalPrice} {clothingJson.currency ? clothingJson.currency : "EUR"}</p>
						</div>

						<button className="buy-button" onClick={onBuyClick}>BUY</button>
					</div>
				</div>

				<div className="shop-info">
					<hr className="line"/>
					<SimpleBar className="simplebar" autoHide={false} scrollbarMinSize={40} classNames={style} forceVisible={true}>
						<div className="more-info-container">
							<div className="wrapper">
								<p className="header">SHOP</p>
								<p className="info">{clothingJson.shops ? clothingJson.shops[0] : ""}</p>
							</div>
							<div className="wrapper">
								<p className="header">STOCK</p>
								{clothingJson.stock ? 
									<p className="info">{clothingJson.stock[0] ? "INSTOCK" : "OUT OF STOCK"}</p>
								: ""}
							</div>

							{ pickShopLink("shipping") === "0" ? "" : 
								<a className="wrapper" href={pickShopLink("shipping")} target="_blank" rel="noopener noreferrer">
									<p className="header">delivery</p>
									<div className="info">
										<p>MORE</p>
										<img src={linkIcon} alt="Shipping more icon"/>
									</div>
								</a>
							}

							{ pickShopLink("returns") === "0" ? "" : 
								<a className="wrapper" href={pickShopLink("returns")} target="_blank" rel="noopener noreferrer">
									<p className="header">Returns</p>
									<div className="info">
										<p>MORE</p>
										<img src={linkIcon} alt="Returns & Refunds more icon"/>
									</div>
								</a>
							}

							{ pickShopLink("taxes") === "0" ? "" : 
								<a className="wrapper" href={pickShopLink("taxes")} target="_blank" rel="noopener noreferrer"> 
									<p className="header">DUTIES & TAXES</p>
									<div className="info">
										<p>MORE</p>
										<img src={linkIcon} alt="Duties & Taxes more icon"/>
									</div>
								</a>
							}
						</div>
					</SimpleBar>
				</div>

				<div className="brand-text">
					{text()}
				</div>
			<Footer/>
		</div>
	)
}

export default ItemView