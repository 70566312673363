import React, {useEffect, useState} from 'react'
import "./topBanner.css"
// import { Link } from 'react-router-dom'


function TopBanner() {

	const [json, setJson] = useState([])

	useEffect(() => {
		if (json.length === 0) {
			fetchJson()
		}

		// window.addEventListener("resize", handleWindowSize) 
		// return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) 



	// FETCH JSON
	const fetchJson = async () => {
		try { 
			const data = await fetch(`https://fashion-api-v1.herokuapp.com/api/BS/outfits/today`) 
			let items = await data.json()	
			setJson(items[0])

		} catch (err) {
			console.log("Fetch JSON error: ", err)
		}
	}
	

	return(
		<div className="top-banner">
			{/* <Link className="content" to={{pathname: `/home/${json.uniqueID}`}}> */}
			<a className="content" href="https://www.instagram.com/deepsale/" target="_blank" rel="noopener noreferrer">
				<div className="new-wrapper">
					<p className="re">DEEP SALE V2 COMING MID 2021</p>
					<p className="en"></p>
				</div>

				{/* <div className="wrapper">
					<div className="infos">
						<p className="header">TODAY HOTTEST DEAL</p>
						<p className="brand">{json.brand}</p>
						<div className="price-container-">
							<p className="salePrice-">{json.salePrice} {json.currency}</p>
							<p className="originalPrice-">{json.originalPrice} {json.currency}</p>
						</div>
					</div>

					<img className="image"
						alt={`Today Hottest Streetwear Sale Clothing`} 
						src={json.image} />
				</div>	 */}
			</a>
			{/* </Link> */}
		</div>
	)
}

export default TopBanner