import React , {useEffect} from 'react'
import "./contact.css"
import Header from "../HELPER/header"
import Footer from "../HELPER/Footer"


function Contact() {

	useEffect(() => {
		document.title = "Deep Sale - Contact"
	}, [])

	return(
		<div className="Contact">
			<Header/>

			<div className="contact-container">
				<a href="mailto:contact@deep-sale.com">CONTACT@DEEP-SALE.COM</a>
			</div>

			<Footer/>
		</div>
	)
}

export default Contact