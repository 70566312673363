import React, {useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
//import MApp from "./APP/MApp"
import Home from "./HOME/home"
import Impressum from "./IMPRESSUM/Impressum"
import Datenschutz from "./DATENSCHUTZ/Datenschutz"
import AppDatenschutz from "./APP-DATENSCHUTZ/appDatenschutz"
import Contact from "./CONTACT/contact"
import Page404 from "./404/404"
import CollectionPage from "./COLLECTION_PAGE/CollectionPage"
import ItemView from "./ITEM_VIEW/itemView"
import firebase from "firebase/app";
import 'firebase/analytics';


function App() {



	useEffect(() => {
		// Your web app's Firebase configuration
		const firebaseConfig = {
			apiKey: "AIzaSyDOCDeSBHe29k5bt1_vB6JnygLWfoMkFgo",
			authDomain: "blacksale-6f3a5.firebaseapp.com",
			databaseURL: "https://blacksale-6f3a5.firebaseio.com",
			projectId: "blacksale-6f3a5",
			storageBucket: "blacksale-6f3a5.appspot.com",
			messagingSenderId: "863196441819",
			appId: "1:863196441819:web:6579888c0ceb89ebd1b0fe",
			measurementId: "G-4DHMH8ZDRV"
		  };

		  
		// const userAcceptStats = getCookie("userAcceptStats")
		// if (userAcceptStats) {
		// 	if (userAcceptStats === "true") { 
				// Initialize Firebase
				firebase.initializeApp(firebaseConfig);
				firebase.analytics();
				// more at https://www.youtube.com/watch?v=iVHRy_uVtm0&t=197s
		// 	} 
		// } 
			
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	
	
	
	// Get Cookie
	// const getCookie = (cname) => {
	// 	var name = cname + "=";
	// 	var ca = document.cookie.split(';');
	// 	for(var i = 0; i < ca.length; i++) {
	// 		var c = ca[i];
	// 		while (c.charAt(0) === ' ') {
	// 		c = c.substring(1);
	// 		}
	// 		if (c.indexOf(name) === 0) {
	// 		return c.substring(name.length, c.length);
	// 		}
	// 	}
	// 	return "";
	// }
	
	return(
		<Router>
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/hottest_deals" exact component={() => CollectionPage("hottest_deals")} />
				<Route path="/streetwear" exact component={() => CollectionPage("streetwear")} />
				<Route path="/sneaker" exact component={() => CollectionPage("sneaker")} />
				<Route path="/accessoires" exact component={() => CollectionPage("accessoires")} />
				{/* <Route path="/app" exact component={MApp} /> */}
				<Route path="/impressum" exact component={Impressum} />
				<Route path="/datenschutz" exact component={Datenschutz} />
				<Route path="/datenschutz-app" exact component={AppDatenschutz} />
				<Route path="/contact" exact component={Contact} />
				<Route path="/:category/:id" exact component={ItemView} />
				<Route path="*" component={Page404} />
			</Switch>
		</Router>
	)
}

export default App