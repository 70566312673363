// const shouldScrollReducer = (state, action) => {
// 	switch(action.type) {
// 		case "ADD_SCROLL_POSITION":
// 			return action.payload
// 		default: 
// 			return state = 0
// 	}
// }

// export default shouldScrollReducer


const shouldScrollReducer = (state = 0, action) => {
	switch(action.type) {
		case "ADD_SCROLL_POSITION":
			return action.payload
		default: 
			return state
	}
}

export default shouldScrollReducer