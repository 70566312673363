import newestApiReducer from "./newestApi"
import hottestApiReducer from "./hottestDealsApi"
import streetwearApiReducer from "./streetwearApi"
import sneakerApiReducer from "./sneakerApi"
import accessoiresApiReducer from "./accessoiresApi"
import textApiReducer from "./textApi"
import shouldScrollReducer from "./shouldScroll"
import collectionSiteNumberReducer from "./collectionSiteNumber"
import linkApiReducer from "./linksApi"
import {combineReducers} from "redux"

const allReducers = combineReducers({
	newestApi: newestApiReducer,
	hottestApi: hottestApiReducer,
	streetwearApi: streetwearApiReducer,
	sneakerApi: sneakerApiReducer,
	accessoiresApi: accessoiresApiReducer,
	textApi: textApiReducer,
	scrollPosition: shouldScrollReducer,
	collectionSiteNumber: collectionSiteNumberReducer,
	linkApi: linkApiReducer
})

export default allReducers