import React , {useEffect, useState} from 'react'
import "./collection.css"
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import {addNewestJson, addHottestDealsJson, addStreetwearJson, addSneakerJson, addAccessoiresJson, addTextJson, change, changeSiteNumber, addLinkJson} from "../../REDUX/actions"


function Collection(props) {

	const [collection, setCollection] = useState([])
	const [isJsonLoading, setIsJsonLoading] = useState(true)
	const [isFetchErr, setIsFetchErr] = useState(false)

	// Redux
	const savedNewestCollection = useSelector(state => state.newestApi)
	const savedHottestCollection = useSelector(state => state.hottestApi)
	const savedStreetwearCollection = useSelector(state => state.streetwearApi)
	const savedSneakerCollection = useSelector(state => state.sneakerApi)
	const savedAccessoiresCollection = useSelector(state => state.accessoiresApi)
	const savedScrollPosition = useSelector(state => state.scrollPosition)
	const savedSiteNumber = useSelector(state => state.collectionSiteNumber)
	const savedText = useSelector(state => state.textApi)
	const savedLinks = useSelector(state => state.linkApi)
	const dispatch = useDispatch()

	//
	const [siteNumber, setSiteNumber] = useState(savedSiteNumber)

	// Scroll
	const executeScroll = position => {window.scrollTo(0, position)}

	useEffect(() => {
		if (props.type === "home") { 
			if (savedNewestCollection === 0) { fetchItems() }
			else { setJsonCollection(savedNewestCollection) }

		} else if (props.type === "hottest_deals") { 
			if (savedHottestCollection === 0) { fetchItems() }
			else { setJsonCollection(savedHottestCollection) }

		} else if (props.type === "sneaker") { 
			if (savedSneakerCollection === 0) { fetchItems() }
			else { setJsonCollection(savedSneakerCollection) }

		} else if (props.type === "streetwear") { 
			if (savedStreetwearCollection === 0) { fetchItems() }
			else { setJsonCollection(savedStreetwearCollection) }	

		} else if (props.type === "accessoires") { 
			if (savedAccessoiresCollection === 0) { fetchItems() }
			else { setJsonCollection(savedAccessoiresCollection) }	
		}

		if (savedText === 0) { fetchText() }
		if (savedLinks === 0) { fetchLinks() }


		// eslint-disable-next-line 
	}, [savedNewestCollection, savedStreetwearCollection, savedHottestCollection, savedSneakerCollection, savedAccessoiresCollection])


	const setJsonCollection = (collection) => {
		setIsJsonLoading(false)
		setCollection(collection)
	}


	useEffect(() => {
		if (savedSiteNumber !== 1) {dispatch(changeSiteNumber(1))}

		// eslint-disable-next-line
	}, [])


	useEffect(() => {
		if (collection.length > 0) {
			if (props.type === "home") { 
				if (savedNewestCollection !== 0) { scrollToItem() } 

			} else if (props.type === "hottest_deals") { 
				if (savedHottestCollection !== 0) { scrollToItem() } 
	
			} else if (props.type === "sneaker") { 
				if (savedSneakerCollection !== 0) { scrollToItem() } 
	
			} else if (props.type === "streetwear") { 
				if (savedStreetwearCollection !== 0) { scrollToItem() } 	
	
			} else if (props.type === "accessoires") { 
				if (savedAccessoiresCollection !== 0) { scrollToItem() } 
			}
		}

		// eslint-disable-next-line 
	}, [collection])

	// SCROLL TO ITEM
	const scrollToItem = () => {
		if (savedScrollPosition !== 0) {
			executeScroll(savedScrollPosition)
			dispatch(change(0))
		}
	}


	// LOAD NEW ITEMS
	window.onscroll = () => {
		if (((window.innerHeight + window.scrollY) >= document.body.offsetHeight) && !isJsonLoading) {
			console.log("at bottom")
			const newNum = siteNumber + 1
			console.log(newNum)
			setSiteNumber(newNum)
		}
	};



	// FETCH JSON
	const fetchItems = async () => {
		setIsJsonLoading(true)

		try { 
			let extraPath = ""
			if (props.type === "sneaker") {extraPath = "/category=Sneaker"}
			else if (props.type === "streetwear") {extraPath = "/category=Streetwear"}
			else if (props.type === "accessoires") {extraPath = "/category=Accessoires"}

			const data = await fetch(`https://fashion-api-v1.herokuapp.com/api/BS/outfits${extraPath}`) 
			// const data = await fetch(`http://localhost:3004/api/${props}`) 
			let items = await data.json()

			if (props.type === "home") { items = sortNewest(items) }
			else if (props.type === "hottest_deals") { items = sortPopular(items) }
			else { items = sortPopular(items) }		
			
			items = filterNonHiddenData(items)
			
			setCollection(items)

			// Redux
			if (props.type === "home") { dispatch(addNewestJson(items)) }
			else if (props.type === "hottest_deals") { dispatch(addHottestDealsJson(items)) }
			else if (props.type === "sneaker") { dispatch(addSneakerJson(items)) }
			else if (props.type === "streetwear") { dispatch(addStreetwearJson(items)) }
			else if (props.type === "accessoires") { dispatch(addAccessoiresJson(items)) }

		} catch (err) {
			console.log("Fetch JSON error: ", err)
			setIsFetchErr(true)
		}

		setIsJsonLoading(false)
	}

	// FETCH Text JSON
	const fetchText = async () => {
		try { 
			const data = await fetch(`https://fashion-api-v1.herokuapp.com/api/BS/text`)
			const items = await data.json()		

			// Redux
			dispatch(addTextJson(items))
		} catch (err) {
			console.log("Fetch JSON from Text API error: ", err)
		}
	}

	// FETCH Links
	const fetchLinks = async () => {
		try { 
			const data = await fetch(`https://fashion-api-v1.herokuapp.com/api/BS/links`)
			const items = await data.json()		

			// Redux
			dispatch(addLinkJson(items))
		} catch (err) {
			console.log("Fetch JSON from Text API error: ", err)
		}
	}




	// Filter
	// Sort functions
	// Price (Low-High)
	// const sortPriceLH = () => {
	// 	props.states.styles.sort((styleA, styleB) => {
	// 		let sumA = 0
	// 		for (let item of styleA.clothings) { sumA += parseFloat(item.price[0].replace(/,/, '.')) }
	// 		let sumB = 0
	// 		for (let item of styleB.clothings) { sumB += parseFloat(item.price[0].replace(/,/, '.')) }
	// 		return sumA - sumB
	// 	})
	// }

	// const sortPriceHL = () => {
	// 	props.states.styles.sort((styleA, styleB) => {
	// 		let sumA = 0
	// 		for (let item of styleA.clothings) { sumA += parseFloat(item.price[0].replace(/,/, '.')) }
	// 		let sumB = 0
	// 		for (let item of styleB.clothings) { sumB += parseFloat(item.price[0].replace(/,/, '.')) }
	// 		return sumB - sumA
	// 	})
	// }

	// Popular
	const sortPopular = (items) => {
		// let newArr = items.sort((itemA, itemB) => {
		// 	return itemB.hotIndex - itemA.hotIndex
		// })

		const newArr = items.sort((itemA, itemB) => {
			if (itemA.hotIndex === itemB.hotIndex) {
				const partsA = itemA.releaseDate.split('/')
				const mydateA = new Date(partsA[2], partsA[1] - 1, partsA[0]); 
	
				const partsB = itemB.releaseDate.split('/')
				const mydateB = new Date(partsB[2], partsB[1] - 1, partsB[0]); 

				return mydateB - mydateA
			}
			return itemB.hotIndex - itemA.hotIndex
		})

		return newArr
	}

	// Newest
	const sortNewest = (items) => {
		const newArr = items.sort((itemA, itemB) => {
			const partsA = itemA.releaseDate.split('/')
			const mydateA = new Date(partsA[2], partsA[1] - 1, partsA[0]); 

			const partsB = itemB.releaseDate.split('/')
			const mydateB = new Date(partsB[2], partsB[1] - 1, partsB[0]); 

			if (itemA.releaseDate === itemB.releaseDate) {
				return itemB.hotIndex - itemA.hotIndex
			}

			return mydateB - mydateA
		})

		return newArr
	}

	const filterNonHiddenData = (items) => {
		return items.filter(item => !item.isHidden)
	}



	const onProductClick = () => {
		dispatch(change(window.pageYOffset))
		dispatch(changeSiteNumber(siteNumber))
	}


	// UI
	const setUI = () => {
		let ui = []

		const limit = siteNumber * 27 >= collection.length ? collection.length : siteNumber * 27

		for (let i = 0; i < limit; i++) {
			ui.push(
				<div className="imgContainer" key={`Designer Clothing Sale ${collection[i].uniqueID}`}>
					<div className="img-wrapper">
						<Link onClick={onProductClick} to={{
							pathname: `/${props.type}/${collection[i].uniqueID}`,
							state: {
								clothing: collection[i]
							}
						}}>
							<img className="image" 
								alt={`${collection[i].brand} ${collection[i].titel} Sale`} 
								src={collection[i].images3x[0]} 
								// srcSet={`${item.imgURLS_185} 185w,
								// 		${item.imgURLS_370} 370w,
								// 		${item.imgURLS_555} 555w,
								// 		${item.imgURL_720} 720w`}
								sizes="(max-width: 1200px) calc(50vw - 5px), 21.53vw">	
							</img>
						</Link> 
					</div>
					<div className="info-wrapper">
						<p className="brand">{collection[i].brand}</p>
						<div className="price"> 
							<p className="salePrice">{collection[i].salePrice} {collection[i].currency !== undefined ? collection[i].currency : "EUR"}</p> 
							<p className="originalPrice">{collection[i].originalPrice} {collection[i].currency !== undefined ? collection[i].currency : "EUR"} </p> 
						</div>
					</div>
				</div>
			)
		}

		return ui

		// return collection.map(item => 
		// 	<div className="imgContainer" key={`Designer Clothing Sale ${item.uniqueID}`}>
		// 		<div className="img-wrapper">
		// 			<Link onClick={changeScrollRedux} to={{
		// 				pathname: `/${props.type}/${item.uniqueID}`,
		// 				state: {
		// 					clothing: item
		// 				}
		// 			}}>
		// 				<img className="image" 
		// 					alt={`${item.brand} ${item.titel} Sale`} 
		// 					src={item.images3x[0]} 
		// 					// srcSet={`${item.imgURLS_185} 185w,
		// 					// 		${item.imgURLS_370} 370w,
		// 					// 		${item.imgURLS_555} 555w,
		// 					// 		${item.imgURL_720} 720w`}
		// 					sizes="(max-width: 1200px) calc(50vw - 5px), 21.53vw">	
		// 				</img>
		// 			</Link> 
		// 		</div>
		// 		<div className="info-wrapper">
		// 			<p className="brand">{item.brand}</p>
		// 			<div className="price"> 
		// 				<p className="salePrice">{item.salePrice} {item.currency !== undefined ? item.currency : "EUR"}</p> 
		// 				<p className="originalPrice">{item.originalPrice} {item.currency !== undefined ? item.currency : "EUR"} </p> 
		// 			</div>
		// 		</div>
		// 	</div>
		// )
	}


	return(
		<div className="Collection" style={{marginTop: props.type === "home" ? "40px" : ""}}>
			<div className="collection-wrapper">
				{setUI()}
			</div>
			<div className="loading-wrapper">
				{isJsonLoading ? <p className="loading">LOADING...</p> : ""}
			</div>

			{isFetchErr ? <p className="error-label">SOMETHING WENT WRONG, TRY AGAIN LATER.</p> : ""}
		</div>
	)
}

export default Collection