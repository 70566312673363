import React , {useEffect} from 'react'
import "./CollectionPage.css"
import Header from "../HELPER/header"
import Footer from "../HELPER/Footer"
import Collection from "../HELPER/COLLECTION/collection"

function CollectionPage(props) {

	const executeScroll = position => {window.scrollTo(0, position)}

	useEffect(() => {
		if (props === "hottest_deals") { document.title = `Deep Sale - Hottest Deals` }
		if (props === "streetwear") { document.title = `Deep Sale - Streetwear` }
		if (props === "sneaker") { document.title = `Deep Sale - Sneaker` }
		if (props === "accessoires") { document.title = `Deep Sale - Accessoires` }

		executeScroll(0)

		// eslint-disable-next-line
	}, [])

	return(
		<div className="CollectionPage">
			<Header cat={props}/>

			<Collection type={props}/>
			
			<Footer/>
		</div>
	)
}

export default CollectionPage