import React , {useEffect} from 'react'
import "./home.css"
import Header from "../HELPER/header"
import Footer from "../HELPER/Footer"
import TopBanner from "./topBanner"
import Collection from "../HELPER/COLLECTION/collection"


function Contact() {

	const executeScroll = position => {window.scrollTo(0, position)}

	useEffect(() => {
		document.title = "Deep Sale - Designer clothes at the best price"
		executeScroll(0)
	}, [])

	return(
		<div className="Home">
			<Header cat={"home"}/>
			<TopBanner/>
			
			<h3 className="newest">NEWEST DEALS</h3>

			<Collection type={"home"}/>

			<Footer/>
		</div>
	)
}

export default Contact