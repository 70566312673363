import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import "./Footer.css"
import { useDispatch } from "react-redux"
import {change, changeSiteNumber} from "../REDUX/actions"
import AppIcon from "../ASSETS/AppIcon.png"

function Footer() {

	const dispatch = useDispatch()

	const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1050 ? true : false)

	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleWindowSize) 
		return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktop])

	const handleWindowSize = () => {
		if (window.innerWidth > 1050) {
			if (!isDesktop) setIsDesktop(true)
		} else {
			if (isDesktop) setIsDesktop(false)
		}
	}

	// On Click
	const onLinkClick = () => {
		dispatch(change(0))
		dispatch(changeSiteNumber(1))
	}

	return(
		<div className="Footer">
			<div className="footer-container">
				{!isDesktop ? 
					<a className="appLink" href="https://apps.apple.com/app/deep-sale/id1498228431">
						<img className="app-icon" src={AppIcon} alt="Deep Sale iOS App Icon"/>
						<p className="text">DOWNLOAD OUR IOS APP</p> 
					</a> : ""
				}

				<div className="links">
					<Link className="label" to="/contact" onClick={onLinkClick}>CONTACT</Link>
					<Link className="label" to="/datenschutz" onClick={onLinkClick}>PRIVACY DISCLAIMER</Link>
					<Link className="label" to="/impressum" onClick={onLinkClick}>LEGAL NOTICE</Link>
				</div>

				{isDesktop ? 
					<a className="appLink" href="https://apps.apple.com/app/deep-sale/id1498228431">
						<img className="app-icon" src={AppIcon} alt="Deep Sale iOS App Icon"/>
						<p className="text">DOWNLOAD OUR IOS APP</p> 
					</a> : ""
				}
			</div>
		</div>
	)
}

export default Footer