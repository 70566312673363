import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import "./appDatenschutz.css"


function AppDatenschutz(props) {

	useEffect(() => {
		manage()
	}, [])

	const manage = () => {
		const w = window
		const d = document
		var loader = function () {
			var s = d.createElement("script"), 
			tag = d.getElementsByTagName("script")[0]; 
			s.src="https://cdn.iubenda.com/iubenda.js"; 
			tag.parentNode.insertBefore(s,tag);
		}; 
		
		if(w.addEventListener)
			{w.addEventListener("load", loader, false)
		} else if(w.attachEvent){
			w.attachEvent("onload", loader);
		}else{
			w.onload = loader;
		}
	}

	return( 
		<div className="AppDatenschutz">
			<div className="brand">
				<div className="wrapper">
					<Link className="h1" to="/"><p><span>DEEP</span> SALE</p></Link>
					<p className="h2">APP DATENSCHUTZ</p>
				</div>
			</div>

			<div className="extern-link">
				<a href="https://www.iubenda.com/privacy-policy/36835367" class="iubenda-white no-brand iubenda-embed iub-body-embed" id="link" title="Privacy Policy">Privacy Policy</a>
			</div>
		</div>
	)
}

export default AppDatenschutz