import React, {useEffect, useState, useRef} from 'react'
import "./header.css"
import {Link} from 'react-router-dom'
import { useDispatch } from "react-redux"
import {change, changeSiteNumber} from "../REDUX/actions"

function Header(props) {
	const [isDesktopNav, setIsDesktopNav] = useState(window.innerWidth > 950 ? true : false) // 1290 normal

	// phone Menu Icon
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	// Ref
	const overlayRef = useRef(null)

	// Redux
	const dispatch = useDispatch()

	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleResize)

		return () => { 
			window.removeEventListener("resize", handleResize) 
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktopNav])

	// Event Listeners
	// Windows Resize
	const handleResize = () => {
		if (window.innerWidth > 950) {
			if (!isDesktopNav) setIsDesktopNav(true)
		} else {
			if (isDesktopNav) setIsDesktopNav(false)
		}
	}



	// On Click
	const onLinkClick = () => {
		dispatch(change(0))
		dispatch(changeSiteNumber(1))
	}

	// Menu Icon Click
	const closeOpenMenu = () => {
		// if (isMenuOpen) clearAllBodyScrollLocks()
		// else disableBodyScroll(overlayRef.current)

		setIsMenuOpen(!isMenuOpen)
	}

	// Overlay
	const onOverlayClick = (e) => {
		if (e.target.className === "overlayBack") { closeOpenMenu() }
	}

	// Styles
	// Phone
	// overlay Manu
	const menuOverlayStyles = {
		// maxHeight: isMenuOpen ? `122px` : "0px"
		maxHeight: isMenuOpen ? `220px` : "0px"
	}

	const listStylesHottest = {
		top: isMenuOpen ? "0px" : "-21px",
	}

	const listStylesStreetwear = {
		top: isMenuOpen ? "0px" : "-23px",
	}

	const listStylesSneaker = {
		top: isMenuOpen ? "0px" : "-25px",
	}

	const listStylesAccessoires = {
		top: isMenuOpen ? "0px" : "-27px",
	}

	const overlayBackStyles = {
		height: isMenuOpen ? "100vh" : "0",
		backgroundColor: isMenuOpen ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0)"
	}

	return(
		<header className="Header">
			<div className="header-container">
				<div className="brand">
					<Link className="h1" to="/" onClick={onLinkClick}><h1><span>DEEP</span> SALE</h1></Link>
				</div>
				<div className="cat">
					<Link className="link" to={{pathname: "/hottest_deals"}} onClick={onLinkClick}>
						<p style={{color: props.cat === "hottest_deals" ? "#282828" : "#939393"}} >HOTTEST DEALS</p>
					</Link>
					<Link className="link" to={{pathname: "/streetwear"}} onClick={onLinkClick}>
						<p style={{color: props.cat === "streetwear" ? "#282828" : "#939393"}} >STREETWEAR</p>
					</Link>
					<Link className="link" to={{pathname: "/sneaker"}} onClick={onLinkClick}>
						<p style={{color: props.cat === "sneaker" ? "#282828" : "#939393"}} >SNEAKER</p>
					</Link>
					<Link className="link" to={{pathname: "/accessoires"}} onClick={onLinkClick}>
						<p style={{color: props.cat === "accessoires" ? "#282828" : "#939393"}} >accessoires</p>
					</Link>
				</div>
			</div>

			<div className="phoneNav-container">
				<nav>
					<button className="menuIcon" onClick={closeOpenMenu}>
						<div className="icon-container">
							<div className={`topLayer${isMenuOpen === true ? "-open" : ""}`}></div>
							<div className={`bottomLayer${isMenuOpen === true ? "-open" : ""}`}></div>
						</div>
					</button>

					{/* <h1 className="brand"><Link to="/" onClick={clearAllBodyScrollLocks}>DEEP SALE</Link></h1> */}
					<Link className="h1" to="/" onClick={onLinkClick}><h1><span>DEEP</span> SALE</h1></Link>
					
					{/* {props.states.shouldDiaplayShare === true ?
						<div className="share">
							<button className="button" onClick={() => props.states.onShareCallack(true)}>
								<img src={ShareIcon} alt="Share outfit button"/>
							</button>
						</div> 
					: "" }*/}
				</nav>
			</div>

			<div className="phoneMenuOverlay" style={menuOverlayStyles}>
				<div className="background" ref={overlayRef}>
					<Link className="text" to={{pathname: "/hottest_deals"}} onClick={onLinkClick} style={listStylesHottest}>
						<p style={{color: props.cat === "hottest_deals" ? "#282828" : "#939393"}} >HOTTEST DEALS</p>
					</Link>
					<Link className="text" to={{pathname: "/streetwear"}} onClick={onLinkClick} style={listStylesStreetwear}>
						<p style={{color: props.cat === "streetwear" ? "#282828" : "#939393"}} >STREETWEAR</p>
					</Link>
					<Link className="text" to={{pathname: "/sneaker"}} onClick={onLinkClick} style={listStylesSneaker}>
						<p style={{color: props.cat === "sneaker" ? "#282828" : "#939393"}} >SNEAKER</p>
					</Link>
					<Link className="text" to={{pathname: "/accessoires"}} onClick={onLinkClick} style={listStylesAccessoires}>
						<p style={{color: props.cat === "accessoires" ? "#282828" : "#939393"}} >accessoires</p>
					</Link>
				</div>
			</div>
			<div className="overlayBack" style={overlayBackStyles} onClick={(e) => onOverlayClick(e)}></div>

			{/* {isFirstRender ? setIsFirstRender(false) : ""} */}
		</header>
	)
}

export default Header